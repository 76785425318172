import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useWindowSize } from "./hooks";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
`;

const HeaderContainer = styled(Container)`
  background-color: #00000045;
  height: 130px;

  @media (orientation: landscape) {
    height: 70px;
    @media (min-width: 1024px) {
      height: 130px;
    }
  }
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`;

const MainContainer = styled(Container)`
  overflow-x: hidden;
  overflow-y: hidden;
  animation-delay: 1s;
  animation-duration: 2s;
  width: 100vw;
`;
const Main = styled(Container)`
  margin: auto;
`;

const FirstNameContainer = styled(Container)`
  margin: 0 auto 1rem;
`;
// const LastNameContainer = styled(Container)`
//   margin: -1.75rem auto 0;
// `;
const Name = styled.div`
  font-size: 4rem;
  line-height: 4rem;
  margin: auto;
  width: 100%;
  text-align: center;
`;

const JobTitle = styled.div`
  font-size: 2rem;
  margin: auto auto auto;
  text-align: center;
  max-width: 720px;
  padding: auto 1rem;
  @media (max-width: 640px) {
    display: none;
  }
`;

const JobTitleMob = styled(JobTitle)`
  display: none;
  @media (max-width: 640px) {
    display: inherit;
  }
`;

const FooterContainer = styled(HeaderContainer)`
  position: fixed;
  top: unset;
  bottom: 0;
`;
const HeaderFooter = styled(Container)`
  margin: auto 0 auto auto;
  width: 100vw;
  display: flex;
  flex-direction: row;
`;
const ResumeContainer = styled(Container)`
  margin: auto;
  height: 100%;
  max-width: 960px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (min-width: 640px) {
    flex-direction: row;
  }
`;

const ResumeText = styled.span`
  margin: auto;
  @media (min-width: 980px) {
    margin: auto 0;
  }
  line-height: 3rem;
  font-size: 1.5rem;
  color: #ffffffd9;
  @media (max-width: 400px) {
    font-size: 1.2rem;
  }
`;

const ResumeLink = styled.a`
  cursor: pointer;
  text-decoration: none;
  // font-size: 1.5rem;
  // font-weight: 700;
  color: #ffffffd9;
`;

const PortfolioLink = styled(ResumeLink)`
  font-weight: 400;
`;

const JobTitleLink = styled(ResumeLink)`
  font-weight: 400;
`;

const App = () => {
  const { windowWidth, windowHeight } = useWindowSize();

  const [viewportHeight, setViewportHeight] = useState(0);
  useEffect(() => {
    setViewportHeight(
      Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
    );
    setTimeout(() => {
      setViewportHeight(
        Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
      );
    }, 1000);
  }, [windowWidth, windowHeight]);

  return (
    <div>
      <div className="bg color-change-2x"></div>
      <HeaderContainer>
        <HeaderFooter>
          <ResumeContainer>
            <ResumeText>
              <JobTitleLink
                target="_blank"
                href="mailto:richard@cinderhousecreative.com"
              >
                Email
              </JobTitleLink>
              {/* <PortfolioLink
                target="_blank"
                href="https://cinderhouse.com/"
              >
                Cinderhouse
              </PortfolioLink> */}
            </ResumeText>
            <ResumeText>
              <PortfolioLink
                target="_blank"
                href="https://www.linkedin.com/in/richardmands"
              >
                LinkedIn
              </PortfolioLink>
            </ResumeText>
          </ResumeContainer>
        </HeaderFooter>
      </HeaderContainer>

      <MainContainer
        className="fade-in"
        style={{
          height: `${viewportHeight}px`,
          transition: "all 1s",
        }}
      >
        <Main>
          <FirstNameContainer>
            <Name>Richard Mands</Name>
          </FirstNameContainer>
          {/* <LastNameContainer>
            <Name>Mands</Name>
          </LastNameContainer> */}
          <Container>
            <JobTitle>Head of Developer Relations | Senior Engineer</JobTitle>
            <JobTitleMob>Head of Developer Relations</JobTitleMob>
            <JobTitleMob>Senior Engineer</JobTitleMob>
          </Container>
        </Main>
      </MainContainer>

      <FooterContainer>
        <HeaderFooter>
          <ResumeContainer>
            <ResumeText>
              <ResumeLink
                target="_blank"
                href="https://drive.google.com/file/d/0B6GYiEdGHBSDM25wckxSNzBYM1k/view?usp=sharing"
              >
                Resume
              </ResumeLink>
            </ResumeText>
            <ResumeText>
              <PortfolioLink
                target="_blank"
                href="https://github.com/richardmands"
              >
                Portfolio
              </PortfolioLink>
            </ResumeText>
          </ResumeContainer>
        </HeaderFooter>
      </FooterContainer>
    </div>
  );
};

export default App;
